<template>
  <div class="co-balloon">
    <slot>ふきだし</slot>
  </div>
</template>

<style scoped>
.co-balloon {
  position: relative;
  display: inline-block;
  padding: 0.5rem 1rem;

  /* color: #fff; /* 表示確認用の色 */

  /* background-color: #000; /* 表示確認用の色 */

  /* border-color: #000; /* 表示確認用の色 */
  @apply rounded;
  &::after {
    position: absolute;
    bottom: -1rem;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -1rem;
    content: '';
    border-top-color: inherit;
    border-top-style: solid;
    border-top-width: 1rem;
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
  }

  /* // 左吹き出し */
  &.co-left::after {
    left: 2rem;
  }
}
</style>
